/* eslint-disable no-undef */
import React, { FC } from 'react';
import { PIN_NUMBER, PIN_STATE } from '../consts';
import { digitalWrite } from '../utils';

interface PropsButton {
  text: string;
  pin: string;
  state: boolean;
  firestore: any;
}

interface PropsCTA {
  text: string;
  callback: () => void;
}

export const Buttons: FC<PropsButton> = ({
  text, pin, state, firestore,
}): JSX.Element => (
  <button
    className={`mt-5 toggle-button${state ? ' active' : ''}`}
    onClick={() => {
      digitalWrite(PIN_NUMBER[pin], state ? PIN_STATE.DIGITAL.OFF : PIN_STATE.DIGITAL.ON, firestore);
    }}
    type="button"
  >
    {text}
  </button>
);

export const CTA: FC<PropsCTA> = ({ text, callback }): JSX.Element => (
  <button
    className="cta"
    onClick={callback}
    type="button"
  >
    {text}
  </button>
);

