import Particle from 'particle-api-js';

export const BUTLER = {
  deviceId: null,
  deviceName: 'butler',
  functions: {
    analogRead: 'analogread',
    analogWrite: 'analogwrite',
    digitalRead: 'digitalread',
    digitalWrite: 'digitalwrite',
  },
  particle: new Particle(),
  password: process.env.REACT_APP_PARTICLE_PASSWORD,
  username: process.env.REACT_APP_PARTICLE_USERNAME,
};

export const webClientID = '923051788747-udphcr0mnat3qs0t8v1n2kkbtqmk1n43.apps.googleusercontent.com';
export const webSecretId = 'a-5wQNy9H96bBrs_Oxq_3_aB';

export const PIN_NUMBER = {
  D0: 'D0',
  D1: 'D1',
  D2: 'D2',
  D3: 'D3',
  D4: 'D4',
  D5: 'D5',
  D6: 'D6',
  D7: 'D7',
  D8: 'D8',
  A0: 'A0',
};

export const PIN_STATE = {
  ANALOG: {
    OFF: '255',
    ON: '0',
  },
  DIGITAL: {
    OFF: 'LOW',
    ON: 'HIGH',
  },
};
