import React, {
  FC, Fragment, useEffect, useState,
} from 'react';
// import imageLandscape from 'images/landscape.png';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Buttons, CTA } from '../components/buttons';
import Title from '../components/content';

interface PropsApp{
  firestore: any;
  firebase: any;
  firebaseUiConfig: any;
}

// eslint-disable-next-line no-undef
const App:FC<PropsApp> = ({ firestore, firebase, firebaseUiConfig }):JSX.Element => {
  const firestoreElectronicsReference = firestore.collection('electronics');
  const [electronicDevices, setElectronicDevices] = useState({});
  const [isLoggedIn, updateAuthState] = useState(false);

  useEffect(() => {
    // Set listener for DB changes
    firestoreElectronicsReference.onSnapshot(docs => {
      const devices = {};
      docs.forEach(doc => {
        devices[doc.id] = (doc.data());
      });

      setElectronicDevices(devices);
    });
  }, []);

  useEffect(() => {
    // Authentication listener
    firebase.auth().onAuthStateChanged(
      user => {
        console.log(user);
        updateAuthState(!!user);
      },
    );
  }, [isLoggedIn]);

  const buttons = [];

  Object.keys(electronicDevices).forEach(device => {
    buttons.push(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <div key={device} className="col-6 col-md-4 col-lg-2">
        <Buttons
          text={electronicDevices[device].name}
          pin={device}
          state={electronicDevices[device].state}
          firestore={firestore}
        />
      </div>,
    );
  });

  return (
    <div className="container">
      {!isLoggedIn && (
        <StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()} />
      )}

      {(isLoggedIn && (firebase.auth().currentUser.email).includes('@vinhood.com')) && (
        <Fragment>
          <Title text={`Welcome, ${firebase.auth().currentUser.displayName}!`} />

          <div className="row">
            {buttons}
          </div>

          <CTA
            text="logout"
            callback={() => {
              firebase.auth().signOut();
            }}
          />
        </Fragment>
      )}

      {((isLoggedIn && !(firebase.auth().currentUser.email).includes('@vinhood.com'))) && (
        <div>
          You need a Vinhood email address to access this app!

          <CTA
            text="logout"
            callback={() => {
              firebase.auth().signOut();
            }}
          />
        </div>
      )}

      {/* <button */}
      {/*  type="submit" */}
      {/*  onClick={() => { */}
      {/*    Notification.requestPermission(status => { */}
      {/*      console.log('Notification permission status:', status); */}
      {/*    }); */}
      {/*  }} */}
      {/* > */}
      {/*  Request notification permissions */}
      {/* </button> */}

      {/* <button */}
      {/*  type="submit" */}
      {/*  onClick={() => { */}
      {/*    if (Notification.permission === 'granted') { */}
      {/*      navigator.serviceWorker.getRegistration().then(reg => { */}
      {/*        if (reg) { */}
      {/*          setTimeout(() => { */}
      {/*            reg.showNotification('Hello world!'); */}
      {/*          }, 5000); */}
      {/*        } */}
      {/*      }); */}
      {/*    } */}
      {/*  }} */}
      {/* > */}
      {/*  Display a notification */}
      {/* </button> */}

      {/* <img src={imageLandscape} alt="" className="landscape" /> */}
    </div>
  );
};

export default App;
