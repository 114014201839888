import React, { FC } from 'react';

interface PropsContent {
  text: string;
}

// eslint-disable-next-line no-undef
const Title: FC<PropsContent> = ({ text }): JSX.Element => (
  <div className="mt-5 title-large">
    {text}
  </div>
);

export default Title;
