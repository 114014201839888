import React from 'react';
import ReactDOM from 'react-dom';
import register from 'registerServiceWorker';
import firebase from 'firebase';

// Containers
import App from './containers/App';

// Styles
import './styles/main.scss';
import { initializeFirebase, loginParticle } from './utils';

loginParticle();
initializeFirebase();

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful.
  // Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

const firestore = firebase.firestore();

ReactDOM.render(
  <React.StrictMode>
    <App firestore={firestore} firebase={firebase} firebaseUiConfig={uiConfig} />
  </React.StrictMode>,
  document.getElementById('root'),
);

register();
