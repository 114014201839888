// import firebase from 'firebase';
import firebase from 'firebase';
import { BUTLER } from './consts';

let token;

export function initializeFirebase():void {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: 'AIzaSyD4rhkg6zP6En6OEru2HL_dLO_fMjCR6cY',
      authDomain: 'butler-vinhood.firebaseapp.com',
      projectId: 'butler-vinhood',
    });
  }
}

export function loginParticle():void {
  BUTLER.particle.login({ username: BUTLER.username, password: BUTLER.password }).then(
    response => {
      token = response.body.access_token;

      const listDevices = BUTLER.particle.listDevices({ auth: token });
      listDevices.then(
        devices => {
          if (devices.body) {
            (devices.body).forEach(device => {
              if (device.name === BUTLER.deviceName && device.functions) {
                BUTLER.deviceId = device.id;
                BUTLER.functions = device.functions;
              }
            });
          }
        },
        err => {
          console.warn('No devices found', err);
        },
      );
    },
    err => {
      console.warn('Could not log in.', err);
    },
  );
}

export function digitalWrite(pin:string, state:string, firestore):void {
  BUTLER.particle.callFunction({
    deviceId: BUTLER.deviceId,
    name: BUTLER.functions[1],
    argument: `${pin}:${state}`,
    auth: token,
  }).then(
    response => {
      console.log('Function called successfully:', response);

      if (response.statusCode === 200) {
        console.log(pin);
        console.log(state === 'HIGH');
        firestore.collection('electronics').doc(pin).update({
          state: state === 'HIGH',
        }).then(() => {
          console.log('firestore updated');
        });
      }
    }, err => {
      console.log(err);
    },
  );
}

export function analogWrite(pin:string, state:string, firestore):void {
  BUTLER.particle.callFunction({
    deviceId: BUTLER.deviceId,
    name: BUTLER.functions[3],
    argument: `${pin}:${state}`,
    auth: token,
  }).then(
    response => {
      console.log('Function called successfully:', response);
      firestore.collection('electronics').doc(pin).update({
        state: state === '0',
      });
    }, err => {
      console.log(err);
    },
  );
}
